.wrapper {
  align-items: stretch;
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;

  &:before {
    width: 100%;
    height: 264px;
    position: absolute;
    top: 0;
    left: 0;
    background: $wrapper-bg;
    content: " ";
  }
}

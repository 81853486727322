body {
  background-size: cover;
  background-position-y: 0;
}

.min-width-992px {
  min-width: 993px;
}

.wrapper {
  &:before {
    display: none;
  }
}

.wrapper.overflow-x-visible {
  &:before {
    min-width: 992px;
  }
}

.brand-img {
  border-radius: 0.5rem;
}

.user-name {
  width: 80px;
  height: 80px;
  font-size: 1rem;
  background-color: #fcb8121c;
}

.sidebar-brand,
.sidebar-brand:hover {
  margin: 0 auto;
  max-width: 200px;
  background: transparent;
}

.btn-white {
  background-color: white;
}

.text-custom-red {
  color: #dc3545;
}

.text-underline {
  text-decoration: underline !important;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-50 {
  min-width: 50px;
}

.width-150 {
  width: 150px;
}

.max-width-150 {
  max-width: 150px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-lg {
  min-width: 992px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.ql-container.ql-snow {
  min-height: 100px;
}

.ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  font-family: inherit;
}

.ql-container {
  font-family: inherit;
  border: none !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.height-100 {
  height: 100%;
}

.min-height-100-px {
  min-height: 100px;
}

.bg-transparent {
  background: transparent !important;
}

.bg-lighter {
  background: #eeeeee4f !important;
}

.bg-lighter-tabs {
  background: #fafafa !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-65 {
  font-size: 0.65rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.align-content-evenly {
  align-content: space-evenly;
}

.word-break-all {
  word-break: break-all;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 150px;
}

.max-width-100 {
  max-width: 100px;
}

.width-170 {
  width: 170px;
}

.width-100-px {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.z-index-2 {
  z-index: 2;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: visible !important;
}

.footer {
  background: #ffffffad;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.bg-light {
  background-color: #e9ecef !important;
}

.conflict i {
  color: #dc3545 !important;
}

.setting-switch {
  .custom-control-label {
    padding-top: 2px;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.font-500 {
  font-weight: 500;
}

.font-300 {
  font-weight: 300;
}

.date-picker .DayPicker-Day {
  color: #8b9898;
  font-size: 0.875em;
  padding: 0.3em !important;

  &.DayPicker-Day--today {
    font-weight: 400;
  }
}

.box-shadow-none {
  box-shadow: none;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

button {
  font-weight: 500 !important;
}

.text-xl {
  font-size: 2.875rem !important;
}

.outline-none,
.outline-none:focus {
  outline: none !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.cursor-default {
  cursor: default !important;
}

.search-jobs.input-group-text {
  border-radius: 0.2rem 100% 90% 0.2rem !important;
}

.job-details-nav {
  .nav-link:not(.active) {
    border: none !important;
  }

  .nav-link.active {
    background-color: white;
    border-bottom: none;
    font-weight: 500;
  }
}

.progress-container {
  margin: 0 auto;
}

.progress-line {
  height: 1px;
  background: #dee2e6;
  margin-bottom: -20px;
}

.status-container {
  z-index: 1;
  width: 100%;
  position: relative;
}

.height-40p {
  height: 40px;
}

.custom-rounded-button {
  padding: 8px;
}

.min-width-900 {
  min-width: 900px;
}

.calendar-container {
  width: 100%;
  padding: 20px 20px 0 20px;
  border: 1px solid #d4e0f3;
  background: #f6faff;
  border-radius: 5px;
}
